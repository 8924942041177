body {
    padding: 0;
    margin: 0;
    font-family: 'Pretendard';
    overscroll-behavior-y: contain;
    /* overscroll-behavior-y: contain; */
    /* overflow: auto; */
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

* {
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
}

@font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/PretendardVariable.ttf');
}

@font-face {
    font-family: 'Pretendard-ExtraLight';
    src: url('assets/fonts/Pretendard-ExtraLight.otf');
}

@font-face {
    font-family: 'Pretendard-Light';
    src: url('assets/fonts/Pretendard-Light.otf');
}
@font-face {
    font-family: 'Pretendard-Medium';
    src: url('assets/fonts/Pretendard-Medium.otf');
}
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('assets/fonts/Pretendard-Regular.otf');
}
@font-face {
    font-family: 'Pretendard-SemiBold';
    src: url('assets/fonts/Pretendard-SemiBold.otf');
}
@font-face {
    font-family: 'Pretendard-Thin';
    src: url('assets/fonts/Pretendard-Thin.otf');
}

/* *,
:after,
:before {
    box-sizing: inherit;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
